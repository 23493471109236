import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  let email = "vendors@veritone.com";
  return (
    <div style={{ "textAlign": "center" }}>
      <a id="forgotPassLink" onClick={toResetPassword}>
        Forgot password
      </a>
      <div className="accountQuestion">Don't have an account?</div>
      <div className="contactUsEmail">
        <a href={'mailto:' + email}>Contact us</a>
      </div>
    </div>
  );
}

import React from 'react';

export function ResetPasswordHeader() {
    return (
        <div className='loginMessageContainer' style={{paddingTop: "0px important!"}}>
            <div className="loginMessageTitle">Forgot your password?</div>
            <div className='loginMessageText'>
                No worries. Enter your email address and we’ll send you a verification code to reset your password.
            </div>
        </div>
    );
}
import styled from 'styled-components';

export const PanesWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-content: flex-start;
`;

export const LeftPane = styled.div`
	background-color: #fff;

	@media (min-width: 768px) {
		width: 40%;
		min-width: 415px;
	}

	@media (max-width: 767px) {
		width: 100%;
	}
`;

export const RightPane = styled.div`
	position: relative;
	overflow: hidden;
	width: 60%;

	@media (max-width: 767px) {
		display: none;
	}
`;

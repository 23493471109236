import React from 'react';
import { useAuthenticator, Button, View } from "@aws-amplify/ui-react";

export function ResetPasswordFooter() {

    const { toSignIn } = useAuthenticator();

    return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Go back to log in
          </Button>
        </View>
        // <div className='loginBackLink'>
        //     <div>
        //         Remember your password?
        //     </div>
        //     <a onClick={toSignIn}>
        //         Go back to log in
        //     </a>
        // </div>
    );
}
import React from 'react';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import ReactGA4 from "react-ga4";
import App from "./App";
import './styles/styles.css';

const rootElement = document.getElementById("root");
ReactGA4.initialize("G-K0BFWSE5GF");
ReactDOM.render(
  <StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </AmplifyProvider>
  </StrictMode>,
  rootElement
);

// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<StrictMode>
//   <AmplifyProvider>
//     <Authenticator.Provider>
//       <App />
//     </Authenticator.Provider>
//   </AmplifyProvider>
// </StrictMode>);

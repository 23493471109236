import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import ReactGA4 from "react-ga4";
import { Home } from "../src/components/Home";
import { Login } from "../src/components/Login";

import awsExports from "./aws-exports";

Amplify.configure(awsExports);

export default function App() {
  ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  const { route } = useAuthenticator(context => [context.route]);

  // Use the value of route to decide which page to render
  return route === 'authenticated' ? <Home /> : <Login />;

}


import React from "react";
import { PanesWrapper, LeftPane, RightPane } from './common/Panes';
import { Footer } from './layout/Footer'
import { PrivacyLinks } from './layout/PrivacyLinks'
import { Header } from "./Header";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import { ResetPasswordHeader } from "./ResetPasswordHeader";
import { ResetPasswordFooter } from "./ResetPasswordFooter";
import { ConfirmResetPasswordHeader } from "./ConfirmResetPasswordHeader";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify"
import { Translations } from "@aws-amplify/ui-components"
import "../styles/loginStyle.css";

const components = {
    Header,
    SignIn: {
        Header: SignInHeader,
        Footer: SignInFooter,
    },
    ResetPassword: {
        Header: ResetPasswordHeader,
        //Footer: ResetPasswordFooter,
    },
    ConfirmResetPassword: {
        //Header: ConfirmResetPasswordHeader,
        Footer: ResetPasswordFooter,
    },
    //VerifyUser: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 ConfirmResetPassword:
    //             </Heading>
    //         );
    //     },
    // }
};

const formFields = {
    signIn: {
        username: {
            labelHidden: false,
            placeholder: 'Name@email.com',
            isRequired: true,
            //label: 'Email',
        },
        password: {
            labelHidden: false,
            placeholder: '',
            isRequired: true,
            //label: 'Password',
        },
    },
    forceNewPassword: {
        password: {
            labelHidden: false,
            placeholder: '',
            //label: 'Password',
        },
        confirm_password: {
            labelHidden: false,
            placeholder: '',
            //label: 'Confirm password',
        }
    },
    resetPassword: {
        username: {
            labelHidden: false,
            placeholder: 'Name@email.com',
            label: 'Email',
            isRequired: true,
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            placeholder: '',
            label: 'Confirmation code',
        },
        password: {
            labelHidden: false,
            placeholder: '',
            label: 'Password',
        },
        confirm_password: {
            labelHidden: false,
            placeholder: '',
            //label: 'Confirm password'
        },
    },
    confirmVerifyUser: {
        confirmation_code: {
            labelHidden: false,
            placeholder: '',
            label: 'Confirmation code'
        }
    }
};

I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
    [Translations.BACK_TO_SIGN_IN]: 'Go back to log in',
    [Translations.CHANGE_PASSWORD]: 'Change password',
    [Translations.EMAIL_LABEL]: 'Email',
    [Translations.EMAIL_PLACEHOLDER]: 'Name@email.com',
    [Translations.RESET_YOUR_PASSWORD]: 'Create a new password',
    [Translations.SEND_CODE]: 'Send me a verification code',
    //TODO: Translations.SUBMIT = Translations.VERIFY_CONTACT_SUBMIT_LABEL = 'Submit'
    [Translations.SUBMIT]: 'Save password',
    //[Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Verify contact',
    [Translations.SIGN_IN_TEXT]: 'Log in',
    'Signing in': 'Please wait...',
    'Submitting': 'Please wait...',
    'Sending': 'Please wait...',
    'Changing': 'Please wait...',
    'Verifying': 'Please wait...',
    'Resend Code': 'Resend code',
});

export function Login() {
    const leftPaneStyle = {
        minHeight: window.innerHeight - 50
    };
    return (
        <div id="loginPageSection">
            <PanesWrapper>
                <LeftPane id="loginLeftPane">
                    <div id="leftPanePositioning" style={leftPaneStyle}>
                        <div id="loginFormWrapper">
                            <Authenticator formFields={formFields} components={components} hideSignUp="true" >
                            </Authenticator>
                        </div>
                    </div>
                    <Footer cssClass={'rmLoginPageFooter'} />
                </LeftPane>
                <RightPane id="loginRightPane">
                    <div className="ie-absolute-fix"></div>
                    <div className="backgroundImage"></div>
                    <div className="imageGradient"></div>
                    <PrivacyLinks cssClass={'rmLoginPageLinks'} />
                </RightPane>
            </PanesWrapper>
        </div >
    );
}

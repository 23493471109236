import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/footer.css';
import styled from 'styled-components';

export const Footer = ({ cssClass, style, minWidth }) => {
	return (
			<FooterWrapper id="rmFooterWrapper" className={cssClass} style={style} minWidth={minWidth}>
				<FooterText id="rmFooterText">
					{/* eslint-disable react/no-danger */}
					<span id="rmFooterSpan" dangerouslySetInnerHTML={{ __html: "© {year} Veritone,&nbsp;Inc. All&nbsp;rights&nbsp;reserved.".replace('{year}', new Date().getFullYear()) }}></span>
				</FooterText>
			</FooterWrapper>
	);
};

Footer.propTypes = {
	cssClass: PropTypes.string,
	style: PropTypes.object,
	minWidth: PropTypes.number
};

const FooterWrapper = styled.div`
	justify-content: flex-start;
	align-items: baseline;
	display: flex;
	min-width: ${props => (props.minWidth ? props.minWidth + 'px' : '1140px')};
`;
const FooterText = styled.div`
	font-size: 12px;
	padding-right: unset;
`;
const SeperatorLine = styled.div`
	height: 16px;
	display: block;
	background-color: #485465;
	width: 1px;
	position: relative;
	margin-top: 18px;
	margin-left: 10px;
	margin-right: 10px;
`;

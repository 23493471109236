import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/footer.css';

export const PrivacyLinks = ({ cssClass }) => {
    return (
        <div className={`privacyLinksWrapper ${cssClass}`} >
            <div id="rmFooterPrivacy">
                <a target="_blank" rel="noopener noreferrer" className="privacyPolicyLink" href="https://www.veritone.com/privacy/">
                Privacy policy
                </a>
            </div>
            <div id="seperatorLine"></div>
            <div id="rmFooterCookiePolicy">
                <a target="_blank" rel="noopener noreferrer" className="privacyPolicyLink" href="https://www.veritone.com/cookie-policy/">
                    Cookie policy
                </a>
            </div>
        </div>
    );
};

PrivacyLinks.propTypes = {
    cssClass: PropTypes.string,
};

import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { Auth, API } from 'aws-amplify';
import ReactGA4 from "react-ga4";
import { useIdleTimer } from "react-idle-timer";

export function Home() {
    const { signOut, user } = useAuthenticator();
    async function listUsers() {
        let apiName = 'AdminQueries';
        let path = '/saveUsers';
        let myInit = {
            query: {},
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        await API.get(apiName, path, myInit).then(res => {
            var invalidUsersMessage = "";
            if (res.countInvalidUsers > 0){
                invalidUsersMessage = `\n\n${res.countInvalidUsers} invalid user${res.countInvalidUsers == 1? " was": "s were"} not uploaded: ${res.invalidUsers}`; 
            }
            alert(`${res.countUsers} users were uploaded to s3.${invalidUsersMessage}`);
        }).catch(err => {
            alert(`Upload users failed.\n\nPlease try again in few minutes.\n\nTechnical information:\n ${err.response?.data?.message} \n ${err.stack}`);
        });
    }

    const [report, setReport] = useState({});
    const [newAccessToken, setNewAccessToken] = useState({});
    const [showLoader, setShowLoader] = React.useState(true);
    const [reportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false,
                },
                pageNavigation: {
                    visible: true,
                    position: 1,
                },
            },
            background: models.BackgroundType.Transparent,
            layoutType: 1,
            customLayout: {
                displayOption: 1
            },
            localeSettings: {
                language: "en",
                formatLocale: "en"
            }
        },
    });
    const timeout = 60000 * 15;
    const onIdle = () => {
        signOut();
    };
    useIdleTimer({ timeout, onIdle: onIdle, crossTab: true });

    const MINUTES_BEFORE_EXPIRATION = 10;

    const setTokenExpirationListener = (tokenExpiration) => {
        // Time in ms before expiration
        const msBeforeExpiration = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

        // Current UTC time in ms
        const msCurrentTime = Date.now();

        // Time until token refresh in milliseconds
        const msToRefresh = tokenExpiration - msCurrentTime - msBeforeExpiration;

        // If token already expired, generate new token and set the access token
        if (msToRefresh <= 0) {
            fetchReportConfig();
        } else {
            console.log(`Embed token will be refreshed in ${msToRefresh} ms`);
            setTimeout(fetchReportConfig, msToRefresh);
        }
    }

    const fetchReportConfig = async () => {
        var url = "https://vendorsdashboardpowerbiembedtoken.azurewebsites.net/api/httptrigger1?code=tpiY2MrPYkLt9Ssst8SjzaJW-a5EaxWOX6A-YP75HQu2AzFuybOOcA%3D%3D"
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user?.attributes["email"] })
        }).then((res) => res.json())
            .then((response) => {
                setNewAccessToken(response.EmbedToken);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        if (newAccessToken.token) {
            report.setAccessToken(newAccessToken.token);
            const msOfExpiration = Date.parse(newAccessToken.expiration);
            // Starting the expiration listener
            setTokenExpirationListener(msOfExpiration);
        }
    }, [newAccessToken]);

    useEffect(() => {
        var url = "https://vendorsdashboardpowerbiembedtoken.azurewebsites.net/api/httptrigger1?code=tpiY2MrPYkLt9Ssst8SjzaJW-a5EaxWOX6A-YP75HQu2AzFuybOOcA%3D%3D"
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user.attributes["email"] })
        })
            .then((res) => res.json())
            .then((response) => {
                setReportConfig({
                    ...reportConfig,
                    embedUrl: response.EmbedUrl,
                    accessToken: response.EmbedToken.token
                });
                setNewAccessToken(response.EmbedToken);
            })
            .catch((error) => console.log(error));
    }, []);
    return (
        <main>
            <div id="page-header">
                <span id="logo"></span>
                <div>
                    {user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('Admins') &&
                        <a target="_blank" style={{ "color": "white", "cursor": "pointer", "paddingRight": "20px" }} onClick={() => listUsers()}>
                            Upload users
                        </a>}
                    <a target="_blank" style={{ "color": "white", "cursor": "pointer" }} onClick={signOut}>
                        Log out
                    </a>
                </div>
            </div>
            <div>
                {showLoader && (
                    <div className="Loader" >
                        <img className="loadingImg" src={require('../static/PL-Loader-dark-blue-small.gif')} />
                        <div className="loadingText">Loading data...</div>
                    </div>)}
                <div id="embedContainer" style={{ visibility: showLoader ? 'hidden' : 'visible' }}>
                    <PowerBIEmbed
                        embedConfig={reportConfig}
                        eventHandlers={
                            new Map([
                                [
                                    "pageChanged",
                                    function (event) {
                                        let page = event.detail.newPage;
                                        console.log("Report page changed:\nPage changed to \"" + page.name + "\" - \"" + page.displayName + "\"" + "\nUser: " + user.attributes["email"]);
                                        ReactGA4.event({
                                            category: page.displayName,
                                            action: "page changed",
                                            label: "User: " + user.attributes["email"],
                                        });
                                    },
                                ],
                                [
                                    "loaded",
                                    function () {
                                        console.log("Report loaded");
                                    },
                                ],
                                [
                                    "rendered",
                                    function () {
                                        console.log("Report rendered");
                                        setShowLoader(false);
                                    },
                                ],
                                [
                                    "error",
                                    function (event) {
                                        console.log(event.detail);
                                    },
                                ],
                            ])
                        }
                        cssClassName={"report-style-class"}
                        getEmbeddedComponent={
                            (embeddedReport) => {
                                setReport(embeddedReport);
                            }
                        }
                    />
                    {/* <div id="footer">
                        <PageFooter cssClass={'rmLoginPageFooter'} />
                        <PrivacyLinks />
                    </div> */}
                </div>
            </div>
        </main>
    );
}
